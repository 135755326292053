import { HeadFC } from 'gatsby';
import React from 'react';

import calendar from '../../assets/calendar-icn.svg';
import { Footnote } from '../../components/Fonts/Footnote';
import { H4, H6 } from '../../components/Fonts/HTags';
import Footer from '../../components/Footer';
import {
  Copy,
  DateInfo,
  Divider,
  List,
  Space,
  SpaceSmall,
  StyledLink,
} from '../../components/LegalInfor';
import NavBar from '../../components/Nav';
import { Seo } from '../../components/Seo';
import { Spacer } from '../../components/Spacer';
import { environments } from '../../config';
import { fontWeightType } from '../../constants/enums';
import Base from '../../layout/Base';

const PrivacyPolicy = () => {
  return (
    <Base>
      <NavBar>
        <H4 color="simpleWhite" fontWeight={fontWeightType.Bold}>
          POLITICA DE TRATAMIENTO DE DATOS PERSONALES CARPEDIEM MEDIAPP
        </H4>
        <Spacer size="22" />
        <DateInfo>
          <img width="18px" height="18px" src={calendar} alt="calendar" />
          <Footnote color="simpleWhite" fontWeight={fontWeightType.Medium}>
            Last updated: April 25th, 2024
          </Footnote>
        </DateInfo>
        <Spacer size="28" />
      </NavBar>
      <Space />
      <Copy>
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          I. OBJETIVO Y ALCANCE
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          La presente política regula el tratamiento de los datos personales de nuestros aliados,
          empleados, contratantes, contratistas, proveedores, usuarios, clientes, consumidores y de
          cualquier persona cuyos datos personales sean o vayan a ser tratados (en adelante se
          denominarán Titulares o Titular) por quienes operan la aplicación CARPEDIEM MEDI APP,
          disponible a través de https://www.carpediemediapp.com/, en calidad de Responsables y/o
          Encargados del tratamiento (en adelante CARPEDIEM), y tiene como finalidad garantizar los
          derechos de los Titulares, informar los mecanismos y procedimientos para hacer efectivos
          esos derechos, preguntas, quejas y reclamos y dar a conocer el tratamiento al que será
          sometida toda su información personal (en adelante denominada Datos Personales) dentro de
          la actividad comercial y la finalidad del mismo, ajustándose a la normativa vigente en
          Colombia.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          II. CARPEDIEM
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para efectos de esta Política, a continuación se relacionan los datos de quienes operan la
          aplicación CARPEDIEM MEDIAPP:
          <br />
          <List>
            <li>Nombre: SEBASTIÁN SÁNCHEZ LÓPEZ</li>
            <li>Identificación: cc 1037587546</li>
            <li>Dirección: calle 3 #43B-48</li>
            <li>Teléfono: 5774831</li>
            <li>Correo: sebassanchez05@gmail.com</li>
          </List>
          <br />
          <List>
            <li>Nombre: JOSE CARLOS ALVAREZ PAYARES</li>
            <li>Identificación: 1128421202</li>
            <li>Dirección: calle 65 #56A-60</li>
            <li>Teléfono: +57(301)670 0770</li>
            <li>Correo: josecarlosalvarezj9@hotmail.com</li>
          </List>
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          III. AUTORIZACIÓN
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Obtendremos tu consentimiento como Titular de los Datos Personales antes de recolectarlos,
          el cual podrán consultar o verificar tú y cualquier autoridad competente.
          <br />
          Para ello, te solicitaremos previamente la autorización para el tratamiento de tus datos
          personales en los diversos escenarios en los cuales los obtenemos, tanto de manera física
          como digital.
          <br />
          En los casos en que tratemos Datos Personales que sean transmitidos o transferidos por
          terceros, será única y exclusiva responsabilidad del tercero obtener todas las
          autorizaciones por parte de los Titulares de los Datos Personales para que CARPEDIEM pueda
          tratarlos con las finalidades aquí contempladas.
          <br />
          En cualquier caso, con la aceptación expresa y previa de esta Política AUTORIZAS el
          tratamiento de tus datos personales por parte de CARPEDIEM, de acuerdo con las
          finalidades, tratamiento y demás condiciones aquí contempladas y ajustadas a la normativa
          vigente en la materia.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          IV. TRATAMIENTO
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          En el desarrollo de nuestras actividades recolectaremos, utilizaremos, administraremos,
          almacenaremos analizaremos, anonimizaremos, indexaremos, segmentaremos, realizaremos
          perfiles, compendiaremos, procesaremos, transmitiremos, transferiremos, efectuaremos
          verificación de tus Datos Personales, efectuaremos cobros y compartiremos información con
          centrales de riesgos, en caso de que esto sea necesario y, en general, realizaremos
          diversas operaciones con tus Datos Personales.
          <br />
          Tus Datos Personales Tratados por nosotros, estarán sometidos únicamente a las finalidades
          que se señalan más adelante o las que sean autorizadas por el titular o
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          V. DERECHOS DEL TITULAR
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Como Titular, cuentas con los siguientes derechos:
          <List>
            <li>Conocer, actualizar y rectificar tus datos personales.</li>
            <li>Solicitar prueba de la autorización otorgada.</li>
            <li>
              Ser informado acerca de los usos o Tratamiento otorgado a los Datos Personales del
              Titular, previa consulta por parte de éste.
            </li>
            <li>
              Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a
              lo dispuesto en la Ley 1581 de 2012, el Decreto 1377 de 2013 y demás disposiciones
              legales sobre la materia, una vez haya agotado el trámite de consulta o reclamo
              directo.
            </li>
            <li>
              Revocar la autorización y/o solicitar la supresión del dato, cuando en el Tratamiento
              no se respeten los principios, derechos y garantías constitucionales y legales.
            </li>
            <li>
              Acceder en forma gratuita a tus datos personales que hayan sido objeto de Tratamiento.
            </li>
            <li>Abstenerte de suministrar datos sensibles.</li>
          </List>
          <br />
          Podrás ejercer tus derechos presentando tu cédula de ciudadanía o cualquier documento de
          identificación. Asimismo, podrán ejercer tus derechos, tus representantes o tutores,
          aportando el documento que los faculta para ello.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          VI. CONTENIDO DE LA INFORMACIÓN
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para el desarrollo de nuestras actividades comerciales y la correcta prestación del
          servicio que ofrecemos, recolectaremos alguna de la información que a continuación se
          describe, sin limitarnos a ella:
          <List>
            <li>
              Usuarios de CARPEDIEM MEDIAPP: Información de identificación personal, incluida pero
              no limitada a: nombres y apellidos, número de cédula o identificación, teléfono,
              dirección, correo electrónico y otro tipo de información que permita identificar a los
              Usuarios.
            </li>
            <li>
              Para los usuarios, los datos de uso se recopilan automáticamente al utilizar el
              servicio, los cuales pueden incluir información como la dirección de protocolo de
              Internet (por ejemplo, la dirección IP) de su dispositivo, el tipo de navegador, la
              versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha
              de su visita, el tiempo que pasa en esas páginas, identificadores únicos de
              dispositivos y otros datos de diagnóstico.
            </li>
            <li>
              Cuando el usuario accede al Servicio por o a través de un dispositivo móvil, podemos
              recopilar cierta información automáticamente, incluyendo, pero sin limitarse a, el
              tipo de dispositivo móvil que utiliza, el identificador único de su dispositivo móvil,
              la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de
              navegador de Internet móvil que utiliza, identificadores únicos de dispositivo y otros
              datos de diagnóstico.
            </li>
            <li>
              También podemos recopilar información que su navegador envía cada vez que visita
              nuestro Servicio o cuando accede al Servicio por o a través de un dispositivo móvil.
            </li>
          </List>
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          VII. DATOS SENSIBLES
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          En la ejecución de nuestros servicios, recolectaremos y trataremos Datos Sensibles, tales
          como, pero sin reducirse a: (i) Imágenes, fotografías y/o grabación de voz; (ii)
          información relativa a salud, género y, en general, (iii) información cuyo Tratamiento
          puede involucrar la discriminación de los Titulares.
          <br />
          En dichos casos, los datos serán tratados con los estándares de seguridad más altos.
          <br />
          Teniendo en cuenta lo anterior, recordamos que el titular (o su representante), tiene la
          facultad de abstenerse de suministrar datos sensibles.
        </Footnote>

        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          VIII. FINALIDADES
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM y quienes, previa autorización, tengan acceso a tus Datos Personales por Ley,
          contrato u otro documento vinculante, realizaremos el Tratamiento con las siguientes
          finalidades:
          <List>
            <li>
              Prestar el servicio que ofrecemos y darte soporte con un problema o corregir algún
              fallo en nuestro servicio, lo cual incluye gestionar tu registro como usuario del
              servicio.
            </li>
            <li>
              Construir bases de datos segmentadas; realizar actividades de archivo, actualización,
              almacenamiento y procesamiento de información, sea por sí mismo o a través de terceros
              con quienes se contrate a tal efecto.
            </li>
            <li>
              Ofrecer programas de fidelización; informarte la existencia de nuevos productos y
              servicios, propios y de terceros.
            </li>
            <li>
              Precisar, analizar y optimizar los productos y servicios existentes y futuros, propios
              y de terceros; exportar información sobre el recurso humano, riesgos crediticios y el
              control y prevención del lavado de activos.
            </li>
            <li>
              Llevar a cabo análisis estadístico de los datos recolectados para optimizar el
              funcionamiento del servicio prestado; para garantizar la efectividad y seguridad de
              las transacciones que se lleven a cabo por el Sitio Web o Aplicación Web de CARPEDIEM
              MEDIAPP.
            </li>
            <li>
              Para cumplir a cabalidad con los contratos o transacciones celebrados con los
              suscriptores, clientes y usuarios, de acuerdo con sus finalidades para ofrecer los
              productos y/o servicios de CARPEDIEM MEDIAPP.
            </li>
            <li>
              Para complementar la información y, en general, adelantar las actividades necesarias
              para gestionar las solicitudes, quejas y reclamos presentadas por los usuarios y por
              terceros, y direccionarlas a las áreas responsables de emitir las respuestas
              correspondientes.
            </li>
            <li>
              Para enviar información y ofertas comerciales de los servicios de CARPEDIEM MEDIAPP,
              así como realizar actividades de mercadeo y/o comercialización de productos y/o
              servicios que preste o que pudiera llegar a prestar u ofrecer CARPEDIEM.
            </li>
            <li>
              Para elaborar estudios de mercado, estadísticas, encuestas, análisis de tendencias del
              mercado, encuestas de satisfacción sobre los productos o servicios de CARPEDIEM
              MEDIAPP.
            </li>
            <li>
              Para la transmisión de datos personales a terceros con los cuales se hayan celebrado
              contratos con el objeto de realizar y cumplir con el producto y/o servicio ofrecido a
              través de CARPEDIEM MEDIAPP para fines comerciales, administrativos y/o operativos.
            </li>
            <li>
              Para gestionar toda la información necesaria para el cumplimiento de las obligaciones
              tributarias y de registros comerciales, corporativos y contables.
            </li>
            <li>Para identificar a los usuarios cuando ingresen a nuestros Sitios Web.</li>
            <li>Para proceder a la facturación y cobro del producto y/o servicio.</li>
            <li>
              Para compartir los Datos Personales con empresas de servicios o empresas de
              “outsourcing” que contribuyan a mejorar o a facilitar las operaciones a través de
              CARPEDIEM dentro de las que se incluyen, medios de pago, seguros o intermediarios de
              la gestión de pagos, compañías de transporte y mensajería, agencias de publicidad,
              entre otros.
            </li>
            <li>
              Realizar la transmisión o transferencia de los datos con terceras personas jurídicas o
              naturales.
            </li>
            <li>
              Para investigar, prevenir, o actuar en caso de actividades ilegales, sospecha de
              fraude, situaciones que involucren la seguridad física de alguna persona, violaciones
              a nuestros Términos de Servicio, o algún otro caso requerido por la ley.
            </li>
            <li>
              Para ponernos en contacto por correo electrónico, llamadas telefónicas, SMS u otras
              formas equivalentes de comunicación electrónica, como las notificaciones push de una
              aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con
              las funcionalidades, productos o servicios contratados, incluidas las actualizaciones
              de seguridad, cuando sea necesario o razonable para su implementación.
            </li>
            <li>
              Podemos compartir tu información con empresas en las que hagamos parte como
              accionistas o directivos, siempre y cuando sea con la finalidad de dar continuidad a
              la prestación de nuestros servicios.
            </li>
          </List>
          <br />
          Velaremos porque las políticas de los terceros que acceden, transmiten, transfieren o
          traten los Datos Personales, tengan estándares similares a los de la presente Política,
          mediante la firma de acuerdos, convenios y/o contratos.
          <br />
          Ten en cuenta que cuando compartes información personal o interactúas de otro modo en las
          áreas públicas con otros usuarios, dicha información puede ser vista por todos los
          usuarios y puede ser distribuida públicamente al exterior.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          IX. PROCEDIMIENTOS
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Con el fin de que el Titular de los datos personales pueda ejercer sus derechos,
          implementaremos los siguientes procedimientos.
          <br />
          Para atender cualquier duda, consulta, queja, pregunta, reclamación o solicitud de
          cualquier tipo de información relacionada con sus datos personales, particularmente, para
          ejercer sus derechos a conocer, actualizar, rectificar y suprimir el dato o revocar la
          autorización otorgada, deberán contactarse con nosotros, mediante un correo electrónico
          que deberá ser dirigido a carpediemapp2023@gmail.com, teniendo en cuenta las siguientes
          condiciones:
          <List>
            <li>
              Consultas: Contamos con mecanismos adecuados para que puedan realizar consultas,
              suministrando toda la información contenida en el registro individual o que esté
              vinculada con su identificación como Titular.
            </li>
            <li>
              Atenderemos las consultas en un término máximo de diez (10) días hábiles contados a
              partir de la fecha de su recibo. Cuando no fuere posible atender la consulta dentro de
              dicho término, le informaremos al interesado antes del vencimiento de los diez (10)
              días, con los motivos de la demora y la fecha en que se atenderá su consulta, la cual
              en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del
              primer plazo.
            </li>
            <li>
              Reclamos: Cuando el Titular considere que la información contenida en nuestra base de
              datos debe ser objeto de corrección, actualización o supresión, o cuando advierta el
              presunto incumplimiento de cualquiera de los deberes contenidos en la Ley 1581 de 2012
              y las normas que lo reglamentan o complementan o cuando quiera revocar la autorización
              del tratamiento de datos personales otorgada, podrá presentarnos un reclamo, el cual
              será tramitado bajo las siguientes reglas:
            </li>
            <li>
              El reclamo lo deberá presentar el Titular, sus causahabientes o su representante. Si
              el reclamo recibido no cuenta con su identificación como Titular, la descripción de
              los hechos que dan lugar al reclamo, la dirección y pruebas del reclamo, lo
              contactaremos dentro de los cinco (5) días siguientes a la recepción para que subsane
              las fallas.Transcurridos dos (2) meses desde la fecha del requerimiento sin que
              presente la información requerida, se entenderá que ha desistido del reclamo.
            </li>
            <li>
              En caso de que quien reciba el reclamo no sea competente para resolverlo, dará
              traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará
              de la situación al interesado.
            </li>
            <li>
              Una vez recibido el reclamo completo, se incluirá en la Base de Datos que mantiene
              CARPEDIEM una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un
              término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el
              reclamo sea decidido.
            </li>
            <li>
              El término máximo para atender el reclamo será de quince (15) días hábiles contados a
              partir del día siguiente a la fecha de su recibo. Cuando no fuera posible atenderlo
              dentro de dicho término te informaremos antes del vencimiento del referido plazo los
              motivos de la demora y la fecha en que se atenderá tu reclamo, lacuál en ningún caso
              podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.
            </li>
          </List>
          <br />
          <List>
            <li>
              Supresión: El Titular tiene el derecho, en todo momento, a solicitarnos la supresión
              (eliminación) de sus Datos Personales cuando considere que los mismos no están siendo
              tratados conforme a los principios, deberes y obligaciones previstas en la Ley 1581 de
              2012; hayan dejado de ser necesarios o pertinentes para la finalidad para la cual
              fueron recabados o; se haya superado el período necesario para el cumplimiento de los
              fines para los que fueron recabados.
            </li>
            <li>
              Esta supresión implica la eliminación total o parcial de la información personal de
              acuerdo con lo solicitado por el Titular en los registros, archivos, bases de datos o
              tratamientos realizados por CARPEDIEM. Sin embargo, podremos negarlo cuando el Titular
              tenga un deber legal o contractual de permanecer en la base de datos; la eliminación
              de datos obstaculice actuaciones judiciales o administrativas vinculadas a
              obligaciones fiscales, a investigación y persecución de delitos o a la actualización
              de sanciones administrativas o; los datos sean necesarios para proteger sus intereses
              jurídicamente tutelados; para realizar una acción en función del interés público, o
              para cumplir con una obligación legalmente adquirida por el Titular.
            </li>
            <li>
              Revocatoria de la Autorización: Puede revocar el consentimiento al tratamiento de sus
              Datos Personales en cualquier momento, siempre y cuando no lo impida una disposición
              legal. Para ello, estableceremos mecanismos sencillos y gratuitos que te permitan
              revocar su consentimiento, al menos por el mismo medio por el que lo otorgó.
            </li>
            <li>
              Puede revocar la autorización total o parcialmente, para ello, siempre deberá indicar
              con cuál tratamiento no estás conforme.
            </li>
          </List>
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          X. TRANSFERENCIA Y TRANSMISIÓN DE DATOS
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Como Titular, autoriza expresamente a CARPEDIEM para transferir y/o transmitir sus Datos
          Personales a terceros (aliados comerciales, proveedores, clientes, contratistas) quienes,
          en calidad de Responsables y/o Encargados de la información, podrán tratar los Datos
          Personales para realizar las gestiones que resulten necesarias para que CARPEDIEM pueda
          brindarle productos y/o servicios de alta calidad.
          <br />
          En cualquier caso, el tercero que reciba los Datos Personales transferidos o transmitidos
          por CARPEDIEM, estará obligado a ajustarse a esta Política de Tratamiento y al estricto
          alcance de la autorización que previamente haya dado el Titular de los datos para realizar
          su respectivo tratamiento.
          <br />
          Nuestro sitio web es operado en Colombia. Si el Titular está localizado en otro país, por
          favor tenga presente que cualquier información que suministre será transferida a Colombia.
          Al usar nuestra aplicación, participar en alguno de nuestros servicios y/o suministrarnos
          su información, está aprobando esa transferencia.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          XI. SEGURIDAD
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Adoptaremos las medidas técnicas, tecnológicas, humanas y administrativas que sean
          necesarias para otorgar seguridad a los registros y archivos que contienen los Datos
          Personales procurando evitar su adulteración, pérdida, consulta, uso o acceso no
          autorizado o fraudulento.
          <br />
          Mantendremos protocolos de seguridad de obligatorio cumplimiento para el personal con
          acceso a los datos de carácter personal y a los sistemas de información o almacenamiento
          de los mismos.
          <br />
          No obstante lo anterior, recuerde que ningún método de transmisión por Internet o de
          almacenamiento electrónico es seguro al 100%. Aunque nos esforzamos por utilizar medios
          comercialmente aceptables para proteger tus Datos Personales, no podemos garantizar su
          seguridad absoluta.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          XII. ENLACES A PÁGINAS DE TERCEROS
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Nuestro servicio puede contener enlaces a otros sitios web no gestionados por nosotros. Si
          hace clic en un enlace de terceros, será dirigido al sitio de ese tercero. Le recomendamos
          enfáticamente que revise la Política de Privacidad de cada sitio que visite.
          <br />
          No tenemos ningún control ni asumimos ninguna responsabilidad por el contenido, las
          políticas de privacidad o las prácticas de los sitios o servicios de terceros.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          XIII. CONTACTO
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para atender cualquier inquietud, consulta, queja, reclamo o solicitud de cualquier tipo
          de información relacionada con tus Datos Personales, particularmente, para ejercer tus
          derechos como Titular de los Datos Personales suministrados a CARPEDIEM podrás contactarse
          mediante el envío de un correo electrónico a carpediemapp2023@gmail.com
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          XIV. MODIFICACIONES
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Nos reservamos el derecho a modificar esta política en cualquier momento y sin previo
          aviso. Toda modificación entrará en vigencia y tendrá efectos frente a los terceros
          relacionados desde su publicación en el canal correspondiente.
        </Footnote>
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          XV. VIGENCIA
        </H6>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          La presente política de datos entra a regir a partir de su publicación. Para mayor
          claridad para los titulares de datos e interesados, siempre se informará la fecha de
          publicación de la Política vigente.
          <br />
          Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en la
          base de datos de CARPEDIEM, durante el tiempo que sea necesario para las finalidades
          mencionadas en esta Política, para las cuales fueron recolectados. De este modo, la
          vigencia de la base de datos está estrechamente relacionada con las finalidades para las
          cuales fueron recolectados los datos personales.
          <br />
          Cuando el Usuario cancela su cuenta, nos aseguramos de que nada permanezca almacenado en
          nuestros servidores después de 30 días, salvo aquellos casos en que la normativa vigente
          nos obligue a conservarlos por un periodo superior.
        </Footnote>
        <Spacer size="78" />
        <Spacer size="78" />
      </Copy>
      <Footer />
    </Base>
  );
};
export default PrivacyPolicy;

export const Head: HeadFC = () => <Seo title="Carpediem MediAPP - Privacy Policy" />;
