import styled from "styled-components";
import { View } from "../View";

export const DateInfo = styled.div`
  background: transparent;
  width: max-content;
  padding: 10px 15px;
  border: 1.3px solid ${({theme}) => theme.colors.simpleWhite};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  img {
    margin-right: 10px;
  }
`;

export const Copy = styled(View)`
  p {
    text-align: justify;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.darkAquaColor};
`;

export const Space = styled.div`
  height: 30px;
`;

export const SpaceSmall = styled.div`
  height: 15px;
`;

export const List = styled.ul`
  margin-left: 25px;
  li{
    list-style-type: disc;
    text-align: left;
  }
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${({theme}) => theme.colors.darkAquaColor};
  color: ${({theme}) => theme.colors.darkAquaColor};
`;