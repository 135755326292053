import styled from 'styled-components';
import { TypographyProps } from '../../types/props';

export const Footnote = styled.p<TypographyProps>`
  font-size: 13px;
  line-height: 18px;
  font-weight: ${({fontWeight}) => fontWeight};
  color: ${({theme, color = 'mainBlack'}) => theme.colors[color]};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 20px;
  }
`;
